import React from 'react';
import { parse } from 'query-string';
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import Dialog from 'material-ui/Dialog';
import LinearProgress from 'material-ui/LinearProgress';

import HelmetHelper from '../../components/HelmetHelper';
import { api as Session } from '../../models/session';

import './Login.scss';

const styles = {
  modalStyle: {
    borderRadius: '0',
    padding: '0',
  },
  contentStyle: {
    maxWidth: '500px',
  },
};

class Login extends React.Component {
  state = {
    error: false,
    success: false,
    email: '',
    submitting: false,
    creatingLoginEmail: false,
    openProgressDialogue: false,
  };

  componentWillReceiveProps(nextProps) {
    // if (nextProps.store.session.loggedIn) {
    //   const { location } = this.props;

    //   if (location.state && location.state.nextPathname) {
    //     window.location = location.state.nextPathname;
    //   } else {
    //     window.location = '/';
    //   }
    // }
  }

  handleLogin = (event) => {
    event.preventDefault();
    this.setState({
      error: false,
      success: false,
      submitting: true,
      creatingLoginEmail: true,
      openProgressDialogue: true,
    });

    const email = window.encodeURIComponent(this.state.email);
    const { location } = window;
    let redirectUrl = '/';

    if (location.search) {
      const query = parse(location.search);
      if (query.redirect_url) {
        redirectUrl = query.redirect_url;
      }
    }

    Session.NEW(email, redirectUrl)
    .then((response) => {
      if (!response.ok) {
        response.json()
        .then(() => {
          this.setState({ error: true, submitting: false, creatingLoginEmail: false });
        });
        throw Error(response.statusText);
      } else {
        this.setState({
          success: true,
          email: '',
          submitting: false,
          creatingLoginEmail: false,
        });
      }
      return response.json();
    })
    .catch((error) => {
      console.error(error);
      if (error) {
        this.setState({ error: true, submitting: false, creatingLoginEmail: false });
      }
    });
  }

  handleChange = (e) => {
    const prop = e.target.name;
    const content = e.target.value;
    this.setState({ [prop]: content });
  }

  handleClose = () => {
    this.setState({
      openProgressDialogue: false,
    });
  }

  render() {
    return (
      <div className='container'>
        <HelmetHelper
          title="Log in"
          image="https://cdn.altcademy.com/assets/images/original/og/Login.jpg"
          url="https://www.altcademy.com/login"
        />

        <div className="row d-flex justify-content-center">
          <div className='logInContainer col'>
            <div className='paper p-4 border border-light shadow d-flex flex-column rounded align-items-center bg-white'>

              <form onSubmit={e => this.handleLogin(e)}>
                <div className='d-flex flex-column'>
                  <TextField
                    style={{ width: 'auto' }}
                    inputStyle={styles.inputStyle}
                    hintStyle={styles.hintStyle}
                    errorStyle={styles.errorStyle}
                    hintText="Email"
                    name="email"
                    type="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                    errorText={this.state.emailError ? 'This field is required' : ''}
                    className='textField'
                    disabled={this.state.submitting}
                    required
                  />
                  <RaisedButton
                    label="log in"
                    type="submit"
                    primary={true}
                    className='mb-3'
                    onClick={this.handleLogin}
                    disabled={this.state.submitting}
                  />
                  <p className="mb-0">
                    <small><em>By providing us with your email, you agree to the terms of our <a href="/legal/privacy" target="_blank">Privacy Policy</a> and <a href="/legal/terms" target="_blank">Terms of Service</a>.</em></small>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>

        <Dialog
          modal={this.state.submitting}
          open={this.state.openProgressDialogue}
          bodyStyle={styles.modalStyle}
          contentStyle={styles.contentStyle}
          onRequestClose={this.handleClose}
          autoScrollBodyContent={true}
        >
          <div className='rounded progressDialogue'>
            { this.state.submitting && (
              <LinearProgress
                mode="indeterminate"
                color='white'
                className='progressBar'
              />)
            }
            <div className='d-flex flex-column align-items-center justify-content-center'>
              { this.state.success && (
                <div className='message d-flex flex-column align-items-center'>
                  <h3>Login email sent! Check your inbox.</h3>

                  <p>If you <b>can't find the email</b>, please check your <b>Spam inbox</b>, and mark us as non-spam.</p>
                </div>
              )}

              { this.state.creatingLoginEmail && (
                <div className='message d-flex flex-column align-items-center'>
                  <h3>Preparing a magic link email...</h3>
                </div>
              )}

              { this.state.error && (
                <div className='message d-flex flex-column align-items-center'>
                  <h3>An account with this email doesn't exists.</h3>
                </div>
              )}
            </div>

            <div className="d-flex justify-content-center">
              { this.state.success && (
                <RaisedButton
                  label="Awesome"
                  onClick={this.handleClose}
                  className='mb-3'
                />)
              }
              { this.state.error && (
                <RaisedButton
                  label="Ok"
                  onClick={this.handleClose}
                  className='mb-3'
                />)
              }
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}

export default Login;
