import { safeCredentials, handleErrors } from '../../utils/fetchHelpers';
import * as Session from './sessionApi';

function updateSession(sessionObj) {
  return {
    type: 'UPDATE_SESSION',
    value: sessionObj,
  };
}

export function authenticateUser() {
  return (dispatch) => Session.authenticateUser()
    .then(handleErrors)
    .then(response => {
      dispatch(updateSession({
        loggedIn: true,
        currentUser: response.currentUser,
      }));
      return response;
    })
    .catch(error => {
      dispatch(updateSession({
        loggedIn: false,
      }));
    })
}

export function createSession(loginToken) {
  return (dispatch) => fetch('/api/v2/sessions/', safeCredentials(
    {
      method: 'POST',
      body: JSON.stringify({
        code: loginToken,
      }),
    }
  ))
  .then(handleErrors)
  .then((response) => {
    return dispatch(authenticateUser());
  });
}

export function destroySession() {
  return (dispatch) => fetch(
    '/api/v2/sessions',
    safeCredentials({ method: 'DELETE' })
  )
  .then(response => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  })
  .then(() => {
    dispatch(updateSession({ loggedIn: false }));
  })
  .catch(error => console.error(error));
}
