import React from 'react';
import ReactDOM from "react-dom";

// CONTAINERS
import Layout from '../containers/Layout';
import Login from '../components/UserAuth/Login';

document.addEventListener('DOMContentLoaded', () => {

  ReactDOM.render(
    <Layout ComponentToRender={Login} />,
    document.getElementById("react-content"),
  )
});
