import React from 'react';
import Helmet from 'react-helmet';

class HelmetHelper extends React.Component {
  render() {
    const meta = [];
    let title;

    if (this.props.title) {
      title = `${this.props.title} | Altcademy™`;
    } else {
      title = 'Altcademy™: Affordable 100% online coding bootcamp';
    }
    meta.push({ name: 'title', content: `${title} | Altcademy™` });
    meta.push({ property: 'og:title', content: `${title} | Altcademy™` });

    const description = this.props.description;
    if (description) {
      meta.push({ name: 'description', content: description });
      meta.push({ property: 'twitter:description', content: description });
      meta.push({ property: 'og:description', content: description });
    }

    const image = this.props.image;
    if (image) {
      meta.push({ property: 'og:image', content: image });
      meta.push({ property: 'twitter:image:src', content: image });
    }

    const keywords = this.props.keywords;
    if (keywords) {
      meta.push({ name: 'keywords', content: keywords });
    }

    const url = this.props.url;
    if (url) {
      meta.push({ property: 'og:url', content: url });
    }

    return (
      <Helmet
        title={title}
        meta={meta}
      />
    );
  }
}

export default HelmetHelper;
