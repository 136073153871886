const initialState = {
  loggedIn: false,
};

export default function sessionReducer(state = initialState, action) {
  switch (action.type) {
    case 'UPDATE_SESSION':
      return {
        ...state,
        ...action.value,
      };
    default:
      return state;
  }
}
