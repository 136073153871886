import { safeCredentials } from '../../utils/fetchHelpers';

export function authenticateUser() {
  return fetch('/api/v2/authenticated_user', safeCredentials());
}

export function NEW(email, redirectUrl) {
  return fetch(`/api/v2/sessions/new?email=${email}&redirect_url=${redirectUrl}`,
    safeCredentials()
  );
}
